// Sudoku.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faEllipsisVertical, faDeleteLeft, faCircleInfo, faGear, faTimes } from '@awesome.me/kit-636b1434d3/icons/classic/regular';
import CompletedModal from './CompletedModal';
import { getSudoku } from 'sudoku-gen';
import sudoku from '../assets/Sudoku.png';
import { updatePlayHistory } from './playHistoryUtil';
import { getStoredUserId } from './Userid';
import sudokuImage from '../assets/Sudoku.png';
import { Capacitor } from '@capacitor/core';
import { Preferences } from '@capacitor/preferences';
import { useNewYorkDate } from './DateTime';
import Timer from './Timer';
import { checkAndroidAndLogEvent } from './FirebaseEvent';
import RatingAlert from './RatingAlert';
import { Keyboard } from '@capacitor/keyboard';
import { useCurrentStreak } from './Streak';
import { useBackHandler } from '../hooks/useBackHandler';

const InfoModal = ({ isOpen, onClose }) => {
    return (
        <div className={`modal ${isOpen ? 'modal-open' : ''}`}>
            <div className="modal-box">
                <h2 className="text-xl font-bold mb-4">How To Play Sudoku</h2>
                <ul className="list-disc list-inside text-lg space-y-2">
                    <li>Fill the 9x9 grid with numbers 1-9.</li>
                    <li>Each row must contain numbers 1-9 without repetition.</li>
                    <li>Each column must contain numbers 1-9 without repetition.</li>
                    <li>Each 3x3 box must contain numbers 1-9 without repetition.</li>
                    <li>Some numbers are given as clues to start.</li>
                </ul>
                <div className="modal-action">
                    <button onClick={onClose} className="btn btn-primary text-white text-xl">Close</button>
                </div>
            </div>
        </div>
    );
};

const SettingsModal = ({ isOpen, onClose, showTimer, setShowTimer, candidateMode, setCandidateMode, autoChecking, setAutoChecking }) => {
    useEffect(() => {
        const modalElement = document.getElementById('settings_modal');
        if (isOpen) {
            modalElement.showModal();
        } else {
            modalElement.close();
        }
    }, [isOpen]);

    return (
        <dialog id="settings_modal" className="modal modal-bottom sm:modal-middle" onClose={onClose}>
            <div className="modal-box relative">
                <button
                    className="btn btn-md btn-circle btn-ghost absolute right-2 top-2"
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </button>
                <h3 className="font-bold text-2xl mb-4">Settings</h3>
                <div className="py-4">
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text text-xl">Show Timer</span>
                            <input 
                                type="checkbox" 
                                className="toggle toggle-primary" 
                                checked={showTimer}
                                onChange={() => setShowTimer(!showTimer)}
                            />
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text text-xl">Auto Candidate Mode</span>
                            <input 
                                type="checkbox" 
                                className="toggle toggle-primary" 
                                checked={candidateMode}
                                onChange={() => setCandidateMode(!candidateMode)}
                            />
                        </label>
                    </div>
                    <div className="form-control">
                        <label className="label cursor-pointer">
                            <span className="label-text text-xl">Auto Checking</span>
                            <input 
                                type="checkbox" 
                                className="toggle toggle-primary" 
                                checked={autoChecking}
                                onChange={() => setAutoChecking(!autoChecking)}
                            />
                        </label>
                    </div>
                </div>
            </div>
        </dialog>
    );
};

const Sudoku = () => {
    const [gameStarted, setGameStarted] = useState(false);
    const [grid, setGrid] = useState([]);
    const [initialGrid, setInitialGrid] = useState([]);
    const [activeCell, setActiveCell] = useState(null);
    const [userInputs, setUserInputs] = useState({});
    const cellRefs = useRef([...Array(9)].map(() => Array(9).fill().map(() => React.createRef())));
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const [isSettingsOpen, setSettingsOpen] = useState(false);
    const [solution, setSolution] = useState([]);
    const [cellStyles, setCellStyles] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [candidateMode, setCandidateMode] = useState(false);
    const [candidates, setCandidates] = useState({});
    const [conflictingCells, setConflictingCells] = useState([]);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);    
    const [userId, setUserId] = useState(null);
    const [difficulty, setDifficulty] = useState('');
    const [currentDate, setCurrentDate] = useState('');
    const location = useLocation();
    const [gameStatus, setGameStatus] = useState({
        easy: null,
        medium: null,
        hard: null
    });
    const [isCompleted, setIsCompleted] = useState(false);
    const newYorkDate = useNewYorkDate(); 
    const [digitCounts, setDigitCounts] = useState({});
    const [isTimerRunning, setIsTimerRunning] = useState(false);
    const [completionTime, setCompletionTime] = useState(0);
    const [showTimer, setShowTimer] = useState(true);
    const [autoChecking, setAutoChecking] = useState(true);
    const [manualCandidateMode, setManualCandidateMode] = useState('normal');
    const [manualCandidates, setManualCandidates] = useState({});
    const [gameWon, setGameWon] = useState(false);
    const currentStreak = useCurrentStreak();

    useBackHandler(currentDate);
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate('/', { state: { date: currentDate } });
    };

    useEffect(() => {
        if (Capacitor.getPlatform() === 'ios') {
            Keyboard.setScroll({ isDisabled: true });
            Keyboard.setAccessoryBarVisible({ isVisible: false });
        }
        return () => {
            if (Capacitor.getPlatform() === 'ios') {
                Keyboard.setScroll({ isDisabled: false });
            }
        };
    }, []);

    const confirmDigitCount = useCallback(() => {
        if (Object.keys(digitCounts).length === 0) return;
        const actualCounts = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0};
        grid.forEach(row => {
          row.forEach(cell => {
            if (cell !== 0) {
              actualCounts[cell]++;
            }
          });
        });
        let needsUpdate = false;
        for (let digit = 1; digit <= 9; digit++) {
          if (actualCounts[digit] !== digitCounts[digit]) {
            needsUpdate = true;
            break;
          }
        }
        if (needsUpdate) {
          setDigitCounts(actualCounts);
          console.log('Adjusted digitCounts:', actualCounts);
        }
    }, [grid, digitCounts]);
      
    useEffect(() => {
        confirmDigitCount();
    }, [activeCell, confirmDigitCount]);

    useEffect(() => {
        if (gameStarted && !isCompleted) {
            setIsTimerRunning(true);
        }
    }, [gameStarted, isCompleted]);

    useEffect(() => {
        let timer;
        if (isTimerRunning) {
            timer = setInterval(() => {
                setCompletionTime(prevTime => prevTime + 1);
            }, 1000);
        } else if (!isTimerRunning && completionTime !== 0) {
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [isTimerRunning, completionTime]);

    const countInitialDigits = useCallback((grid) => {
        const counts = {1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0};
        grid.forEach(row => {
            row.forEach(cell => {
                if (cell !== 0) {
                    counts[cell]++;
                }
            });
        });
        return counts;
    }, []);

    const loadSavedGame = useCallback(async (date, gameDifficulty) => {
        try {
            const { value } = await Preferences.get({ key: 'sudoku' });
            if (value) {
                const savedGames = JSON.parse(value);
                if (savedGames[date] && savedGames[date][gameDifficulty]) {
                    const savedGame = savedGames[date][gameDifficulty];
                    setGrid(savedGame.grid);
                    setInitialGrid(savedGame.initialGrid);
                    setSolution(savedGame.solution);
                    setUserInputs(savedGame.userInputs);
                    setCellStyles(savedGame.cellStyles);
                    setIsCompleted(savedGame.completed);
                    setDigitCounts(savedGame.digitCounts);
                    setGameStarted(true);
                    setCompletionTime(savedGame.completionTime || 0);
                    setIsTimerRunning(!savedGame.completed);
                    return true;
                }
            }
        } catch (error) {
            console.error('Error loading saved game:', error);
        }
        return false;
    }, []);

    const saveGame = useCallback(async () => {
        try {
            const { value } = await Preferences.get({ key: 'sudoku' });
            let savedGames = value ? JSON.parse(value) : {};
            
            if (!savedGames[currentDate]) {
                savedGames[currentDate] = {};
            }
            
            savedGames[currentDate][difficulty] = {
                grid: grid,
                initialGrid: initialGrid,
                solution: solution,
                userInputs: userInputs,
                cellStyles: cellStyles,
                completed: isCompleted,
                digitCounts: digitCounts,
                completionTime: completionTime
            };
            
            await Preferences.set({
                key: 'sudoku',
                value: JSON.stringify(savedGames)
            });

            setGameStatus(prevStatus => ({
                ...prevStatus,
                [difficulty]: isCompleted ? 'completed' : 'inprogress'
            }));
        } catch (error) {
            console.error('Error saving game:', error);
        }
    }, [currentDate, difficulty, grid, initialGrid, solution, userInputs, cellStyles, isCompleted, digitCounts, completionTime]);

    useEffect(() => {
        const loadGameStatus = async () => {
            try {
                const { value } = await Preferences.get({ key: 'sudoku' });
                if (value) {
                    const savedGames = JSON.parse(value);
                    if (savedGames[currentDate]) {
                        const newStatus = {
                            easy: savedGames[currentDate].easy ? (savedGames[currentDate].easy.completed ? 'completed' : 'inprogress') : null,
                            medium: savedGames[currentDate].medium ? (savedGames[currentDate].medium.completed ? 'completed' : 'inprogress') : null,
                            hard: savedGames[currentDate].hard ? (savedGames[currentDate].hard.completed ? 'completed' : 'inprogress') : null
                        };
                        setGameStatus(newStatus);
                    }
                }
            } catch (error) {
                console.error('Error loading game status:', error);
            }
        };

        loadGameStatus();
    }, [currentDate]);

    useEffect(() => {
        if (gameStarted) {
            saveGame();
        }
    }, [grid, userInputs, cellStyles, gameStarted, saveGame]);
    useEffect(() => {
        const fetchUserId = async () => {
            const id = await getStoredUserId();
            setUserId(id);
        };
        fetchUserId();
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const dateParam = searchParams.get('date');
        if (dateParam) {
            setCurrentDate(dateParam);
        } else {
            setCurrentDate(newYorkDate);
        }
    }, [location, newYorkDate]);

    const focusActiveCell = useCallback(() => {
        if (activeCell && cellRefs.current[activeCell.rowIndex][activeCell.colIndex].current) {
            cellRefs.current[activeCell.rowIndex][activeCell.colIndex].current.focus();
        }
    }, [activeCell]);

    const findFirstEmptyCell = (grid) => {
        for (let row = 0; row < 9; row++) {
            for (let col = 0; col < 9; col++) {
                if (grid[row][col] === 0) {
                    return { rowIndex: row, colIndex: col };
                }
            }
        }
        return null; // Return null if no empty cell is found
    };

    const sendGameData = useCallback(async (solved, revealed, difficulty) => {
        if (!currentDate) return;
        try {
            const source = ['ios', 'android'].includes(Capacitor.getPlatform()) ? Capacitor.getPlatform() : 'web';
            const payload = {
                date: currentDate,
                game: "sudoku",
                userid: userId,
                solved: solved,
                revealed: revealed,
                difficulty: difficulty,
                source: source,
                streak: Number.isInteger(currentStreak) ? currentStreak : 0
            };
            await axios.post('https://nu5koio7l8.execute-api.us-east-1.amazonaws.com/prod', payload);
        } catch (error) {
            console.error("Error triggering Lambda:", error);
        }
    }, [userId, currentDate, currentStreak]);

    const startGame = useCallback(async (gameDifficulty) => {
        setDifficulty(gameDifficulty);
        const hasSavedGame = await loadSavedGame(currentDate, gameDifficulty);
        if (!hasSavedGame) {
            const sudoku = getSudoku(gameDifficulty);
            const puzzleGrid = convertStringToGrid(sudoku.puzzle);
            const solutionGrid = convertStringToGrid(sudoku.solution);

            setGrid(puzzleGrid);
            setInitialGrid(puzzleGrid.map(row => [...row]));
            setSolution(solutionGrid);
            setGameStarted(true);
            setIsTimerRunning(true);

            const initialCounts = countInitialDigits(puzzleGrid);
            setDigitCounts(initialCounts);

            const firstEmptyCell = findFirstEmptyCell(puzzleGrid);
            if (firstEmptyCell) {
                setActiveCell(firstEmptyCell);
                setTimeout(() => {
                    if (cellRefs.current[firstEmptyCell.rowIndex][firstEmptyCell.colIndex].current) {
                        cellRefs.current[firstEmptyCell.rowIndex][firstEmptyCell.colIndex].current.focus();
                    }
                }, 0);
            }
            updatePlayHistory(currentDate, 'sudoku', 'started');
        } 
        sendGameData(false, false, gameDifficulty);
    }, [currentDate, loadSavedGame, sendGameData, countInitialDigits]);

    const updateDigitCount = useCallback((digit, increment) => {
        setDigitCounts(prevCounts => {
            const newCount = (prevCounts[digit] || 0) + (increment ? 1 : -1);
            return { ...prevCounts, [digit]: newCount };
        });
    }, []);

    const convertStringToGrid = (str) => {
        return str.split('').reduce((grid, char, index) => {
            const row = Math.floor(index / 9);
            const col = index % 9;
            if (!grid[row]) grid[row] = [];
            grid[row][col] = char === '-' ? 0 : parseInt(char, 10);
            return grid;
        }, []);
    };

    const checkConflicts = useCallback((currentGrid) => {
        const conflicts = new Set();
    
        for (let row = 0; row < 9; row++) {
            for (let col = 0; col < 9; col++) {
                const value = currentGrid[row][col];
                if (value === 0) continue; // Skip empty cells
    
                // Check row
                for (let i = 0; i < 9; i++) {
                    if (i !== col && currentGrid[row][i] === value) {
                        conflicts.add(`${row}-${col}`);
                        conflicts.add(`${row}-${i}`);
                    }
                }
    
                // Check column
                for (let i = 0; i < 9; i++) {
                    if (i !== row && currentGrid[i][col] === value) {
                        conflicts.add(`${row}-${col}`);
                        conflicts.add(`${i}-${col}`);
                    }
                }
    
                // Check 3x3 box
                const boxRow = Math.floor(row / 3) * 3;
                const boxCol = Math.floor(col / 3) * 3;
                for (let i = 0; i < 3; i++) {
                    for (let j = 0; j < 3; j++) {
                        const r = boxRow + i;
                        const c = boxCol + j;
                        if ((r !== row || c !== col) && currentGrid[r][c] === value) {
                            conflicts.add(`${row}-${col}`);
                            conflicts.add(`${r}-${c}`);
                        }
                    }
                }
            }
        }
    
        return Array.from(conflicts);
    }, []);

    const handleHint = useCallback(() => {
        let minCandidates = 10;  // More than the maximum possible (9)
        let cellToHint = null;
    
        for (let row = 0; row < 9; row++) {
            for (let col = 0; col < 9; col++) {
                if (grid[row][col] === 0) {  // Only consider empty cells
                    const candidatesForCell = candidates[`${row}-${col}`] || [];
                    if (candidatesForCell.length < minCandidates && candidatesForCell.length > 0) {
                        minCandidates = candidatesForCell.length;
                        cellToHint = { rowIndex: row, colIndex: col };
                    }
                }
            }
        }
    
        if (cellToHint) {
            setActiveCell(cellToHint);
            if (cellRefs.current[cellToHint.rowIndex][cellToHint.colIndex].current) {
                cellRefs.current[cellToHint.rowIndex][cellToHint.colIndex].current.focus();
            }
        }
        setDropdownOpen(false);
    }, [grid, candidates, cellRefs]);

    const calculateCandidates = useCallback(() => {
        if (!grid || grid.length === 0 || grid[0].length === 0) {
            return {};  // Return an empty object if grid is not properly initialized
        }
        const candidates = {};
        for (let row = 0; row < 9; row++) {
            for (let col = 0; col < 9; col++) {
                if (grid[row][col] === 0) {
                    const validNumbers = new Set([1, 2, 3, 4, 5, 6, 7, 8, 9]);
                    // Check row
                    grid[row].forEach(num => validNumbers.delete(num));
                    // Check column
                    for (let i = 0; i < 9; i++) {
                        validNumbers.delete(grid[i][col]);
                    }
                    // Check 3x3 box
                    const boxRow = Math.floor(row / 3) * 3;
                    const boxCol = Math.floor(col / 3) * 3;
                    for (let i = 0; i < 3; i++) {
                        for (let j = 0; j < 3; j++) {
                            validNumbers.delete(grid[boxRow + i][boxCol + j]);
                        }
                    }
                    candidates[`${row}-${col}`] = Array.from(validNumbers);
                }
            }
        }
        return candidates;
    }, [grid]);

    useEffect(() => {
        setCandidates(calculateCandidates());
    }, [grid, calculateCandidates]);

    const arrangeCandidate = (candidates) => {
        const arranged = Array(9).fill(null);
        candidates.forEach(num => {
            arranged[num - 1] = num;
        });
        return arranged;
    };

    const checkCompletion = useCallback((currentGrid) => {
        for (let row = 0; row < 9; row++) {
            for (let col = 0; col < 9; col++) {
                if (currentGrid[row][col] !== solution[row][col]) {
                    return;
                }
            }
        }
        console.log('Puzzle completed!');
        setIsCompleted(true);
        setGameWon(true)
        updatePlayHistory(currentDate, 'sudoku', 'won');
        sendGameData(true, false, difficulty);
        setIsTimerRunning(false);
        checkAndroidAndLogEvent("sudoku")
        setTimeout(() => { setIsModalOpen(true); }, 750); 
    }, [solution, sendGameData, difficulty, currentDate]);

    const handleInputChange = useCallback((value, rowIndex, colIndex) => {
        const cellKey = `${rowIndex}-${colIndex}`;
        const newGrid = grid.map(row => [...row]);
        const oldValue = newGrid[rowIndex][colIndex];
        let newValue = 0;
    
        if (manualCandidateMode === 'normal') {
            const trimmedValue = value.slice(-1);
            newValue = trimmedValue !== '' ? Number(trimmedValue) : 0;
            if ((newValue >= 1 && newValue <= 9) || newValue === 0) {
                newGrid[rowIndex][colIndex] = newValue;
                setUserInputs(prev => {
                    const newUserInputs = { ...prev };
                    if (newValue === 0) {
                        delete newUserInputs[cellKey];
                    } else {
                        newUserInputs[cellKey] = newValue;
                    }
                    return newUserInputs;
                });
                if (newValue !== 0) updateDigitCount(newValue, true);
                if (oldValue !== 0) updateDigitCount(oldValue, false);
            }
        } else {
            const valueNumber = Number(value);
            if (valueNumber >= 1 && valueNumber <= 9) {
                setManualCandidates(prev => {
                    const currentCandidates = prev[cellKey] || [];
                    const updatedCandidates = currentCandidates.includes(valueNumber)
                        ? currentCandidates.filter(num => num !== valueNumber)
                        : [...currentCandidates, valueNumber].sort((a, b) => a - b);
                    return { ...prev, [cellKey]: updatedCandidates };
                });
            }
            return;
        }
    
        setGrid(newGrid);
        setCellStyles(prev => ({ ...prev, [cellKey]: 'text-black' }));
        setCandidates(calculateCandidates());
        setConflictingCells(checkConflicts(newGrid));
        checkCompletion(newGrid);
    }, [grid, calculateCandidates, checkConflicts, checkCompletion, updateDigitCount, manualCandidateMode]);

    const inputNumber = useCallback((num) => {
        if (!activeCell || (manualCandidateMode === 'normal' && digitCounts[num] >= 9)) return;
        const { rowIndex, colIndex } = activeCell;
        handleInputChange(String(num), rowIndex, colIndex);
        focusActiveCell();
    }, [activeCell, handleInputChange, focusActiveCell, digitCounts, manualCandidateMode]);

    const renderDigitButton = useCallback((digit) => {
        const isDisabled = manualCandidateMode === 'normal' && digitCounts[digit] >= 9;
        return (
            <button
                key={digit}
                className={`btn btn-outline btn-lg w-16 ${isDisabled ? 'btn-disabled opacity-50 cursor-not-allowed' : ''} ${manualCandidateMode === 'candidate' ? 'text-sm' : 'text-lg'}`}
                onClick={() => inputNumber(digit)}
                disabled={isDisabled}
                aria-disabled={isDisabled}
            >
                {digit}
            </button>
        );
    }, [digitCounts, inputNumber, manualCandidateMode]);

    const handleBackspace = useCallback(() => {
        if (!activeCell) return;
        const { rowIndex, colIndex } = activeCell;
        handleInputChange('', rowIndex, colIndex);
        focusActiveCell();
    }, [activeCell, handleInputChange, focusActiveCell]);

    const handleKeyDown = useCallback((event) => {
        if (!activeCell) return;
        const key = event.key;
        if (/^[1-9]$/.test(key)) {
            inputNumber(key)
        } else if (key === 'Backspace' || key === 'Delete') {
            handleBackspace();
        } else {
            const { rowIndex, colIndex } = activeCell;
            const increments = {
                ArrowLeft: [0, -1],
                ArrowRight: [0, 1],
                ArrowUp: [-1, 0],
                ArrowDown: [1, 0],
            };
            if (!increments[event.key]) return;
            let newRow = rowIndex + increments[event.key][0];
            let newCol = colIndex + increments[event.key][1];
            let foundRow = null;
            let foundCol = null;
            while (newRow >= 0 && newRow < 9 && newCol >= 0 && newCol < 9) {
                if (initialGrid[newRow][newCol] === 0) {
                    foundRow = newRow;
                    foundCol = newCol;
                    break;
                }
                newRow += increments[event.key][0];
                newCol += increments[event.key][1];
            }
            if (foundRow !== null && foundCol !== null) {
                setActiveCell({ rowIndex: foundRow, colIndex: foundCol });
                setTimeout(() => {
                    if (cellRefs.current[foundRow][foundCol].current) {
                        cellRefs.current[foundRow][foundCol].current.focus();
                    }
                }, 1);
            }
        }
    }, [activeCell, initialGrid, cellRefs, inputNumber, handleBackspace]);    
    
    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, [handleKeyDown]); 

    const handleFocus = (rowIndex, colIndex) => {
        setActiveCell({ rowIndex, colIndex });
    };

    const checkCell = useCallback(() => {
        if (!activeCell) return;
        const { rowIndex, colIndex } = activeCell;
        const userInput = userInputs[`${rowIndex}-${colIndex}`];
        const correctValue = solution[rowIndex][colIndex];
        const newStyles = { ...cellStyles };
    
        if (userInput !== undefined) {
            if (userInput === correctValue) {
                newStyles[`${rowIndex}-${colIndex}`] = 'text-blue-500';
            } else {
                newStyles[`${rowIndex}-${colIndex}`] = 'text-red-500';
            }
        } else {
            newStyles[`${rowIndex}-${colIndex}`] = '';
        }
    
        setCellStyles(newStyles);
        setDropdownOpen(false);
    }, [activeCell, userInputs, solution, cellStyles]);

    const checkPuzzle = () => {
        const newStyles = {};
        grid.forEach((row, rowIndex) => {
            row.forEach((cell, colIndex) => {
                if (initialGrid[rowIndex][colIndex] === 0) {
                    const correctValue = solution[rowIndex][colIndex];
                    const userInput = userInputs[`${rowIndex}-${colIndex}`];
                    if (userInput !== undefined && userInput !== correctValue) {
                        newStyles[`${rowIndex}-${colIndex}`] = 'text-red-500';
                    } else if (userInput !== undefined && userInput === correctValue) {
                        newStyles[`${rowIndex}-${colIndex}`] = 'text-blue-500';
                    }
                }
            });
        });
        setCellStyles(newStyles);
        setDropdownOpen(false);
    };

    const resetPuzzle = () => {
        setGrid(initialGrid.map(row => [...row]));
        setUserInputs({}); 
        setDropdownOpen(false);
        setIsCompleted(false);
        setCompletionTime(0);
        setIsTimerRunning(true);
    };

    const revealCell = useCallback(() => {
        if (!activeCell) return;
        const { rowIndex, colIndex } = activeCell;
        const correctValue = solution[rowIndex][colIndex];
        const newGrid = grid.map((row, rIndex) => 
            row.map((cell, cIndex) => 
                rIndex === rowIndex && cIndex === colIndex ? correctValue : cell
            )
        );
        const newUserInputs = {
            ...userInputs,
            [`${rowIndex}-${colIndex}`]: correctValue
        };
        setGrid(newGrid);
        setUserInputs(newUserInputs);
        setDropdownOpen(false);
        checkCompletion(newGrid)
        setConflictingCells(checkConflicts(newGrid));
    }, [activeCell, grid, userInputs, solution, checkCompletion, checkConflicts]);

    const revealPuzzle = useCallback(() => {
        const newGrid = grid.map((row, rIndex) =>
            row.map((cell, cIndex) =>
                solution[rIndex][cIndex]
            )
        );
        const newUserInputs = {};
        newGrid.forEach((row, rIndex) => {
            row.forEach((cell, cIndex) => {
                newUserInputs[`${rIndex}-${cIndex}`] = cell;
            });
        });
        setGrid(newGrid);
        setUserInputs(newUserInputs);
        setDropdownOpen(false);
        setIsCompleted(true);
        updatePlayHistory(currentDate, 'sudoku', 'lost');
        sendGameData(false, true, difficulty);
        setIsTimerRunning(false);
        setTimeout(() => { setIsModalOpen(true); }, 750); 
    }, [grid, solution, sendGameData, difficulty, currentDate]);
    
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!event.target.closest('.dropdown')) {
                setDropdownOpen(false);
            }
        };
    
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    if (!gameStarted) {
        return (
            <div className="flex flex-col min-h-screen rajdhani-font bg-[#a1d0a6]">
                <div className="flex justify-start p-4">
                    <button onClick={handleBackClick} className="btn btn-ghost z-10 dark:text-black">
                        <FontAwesomeIcon icon={faArrowLeft} className="text-3xl" />
                    </button>
                </div>
                <div className="flex-1 flex flex-col items-center justify-center" style={{ marginTop: '-20%' }}>
                    <figure className="h-48 mb-4">
                        <img src={sudoku} alt="Sudoku" className="h-full object-contain"/>
                    </figure>
                    <h1 className="text-6xl font-bold mb-8 dark:text-black">Sudoku</h1>
                    <div className="flex flex-col space-y-4">
                        <button 
                            className={`btn w-40 rounded-full bg-black text-white text-xl hover:bg-gray-800 border-none
                                ${['completed', 'won', 'lost'].includes(gameStatus.easy) ? 'ring-4 ring-success' : 
                                    ['inprogress', 'started'].includes(gameStatus.easy) ? 'ring-4 ring-info' : ''}`}
                            onClick={() => startGame('easy')}
                        >
                            Easy
                        </button>
                        <button 
                            className={`btn w-40 rounded-full bg-black text-white text-xl hover:bg-gray-800 border-none
                                ${gameStatus.medium === 'completed' ? 'ring-4 ring-success' : 
                                  gameStatus.medium === 'inprogress' ? 'ring-4 ring-info' : ''}`}
                            onClick={() => startGame('medium')}
                        >
                            Medium
                        </button>
                        <button 
                            className={`btn w-40 rounded-full bg-black text-white text-xl hover:bg-gray-800 border-none
                                ${gameStatus.hard === 'completed' ? 'ring-4 ring-success' : 
                                  gameStatus.hard === 'inprogress' ? 'ring-4 ring-info' : ''}`}
                            onClick={() => startGame('hard')}
                        >
                            Hard
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    
    return (
        <div className="rajdhani-font">
            <style>{`input[type="text"]:disabled {opacity: 1;}`}</style>
            <div className="shadow-b mb-2 flex justify-between items-center rajdhani-font relative">
                <button onClick={handleBackClick} className="btn btn-ghost">
                    <FontAwesomeIcon icon={faArrowLeft} className="text-2xl" />
                </button>
                {showTimer && 
                    <div className="ml-8">
                        <Timer 
                            time={completionTime}
                            isRunning={isTimerRunning}
                        />
                    </div>
                }
                <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center justify-center">
                    <img src={sudokuImage} alt="Sudoku" className="h-6 mr-2 dark:invert" />
                    <span className="font-bold text-lg">Sudoku</span>
                </div>
                <button onClick={() => setIsInfoModalOpen(true)} className="btn btn-ghost btn-circle ml-auto">
                    <FontAwesomeIcon icon={faCircleInfo} className="text-2xl" />
                </button>
                <div className="dropdown dropdown-end">
                    <button tabIndex="0" className="btn btn-ghost btn-circle" onClick={() => setDropdownOpen(!isDropdownOpen)}>
                        <FontAwesomeIcon icon={faEllipsisVertical} className="text-2xl" />
                    </button>
                    {isDropdownOpen && (
                        <ul tabIndex="0" className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52 z-50 text-xl">
                            <li><button onClick={handleHint}>Hint</button></li>
                            <li><button onClick={checkCell}>Check Square</button></li>
                            <li><button onClick={checkPuzzle}>Check Puzzle</button></li>
                            <li><button onClick={revealCell}>Reveal Square</button></li>
                            <li><button onClick={revealPuzzle}>Reveal Puzzle</button></li>
                            <li><button onClick={resetPuzzle}>Reset Puzzle</button></li>
                        </ul>
                    )}
                <button 
                    className="btn btn-ghost btn-circle" 
                    onClick={() => setSettingsOpen(true)}
                >
                    <FontAwesomeIcon icon={faGear} className="text-2xl" />
                </button>
                <SettingsModal 
                    isOpen={isSettingsOpen}
                    onClose={() => setSettingsOpen(false)}
                    showTimer={showTimer}
                    setShowTimer={setShowTimer} 
                    candidateMode={candidateMode} 
                    setCandidateMode={setCandidateMode}  
                    autoChecking={autoChecking}
                    setAutoChecking={setAutoChecking}
                />
                </div>
            </div>
            <div className='flex justify-center items-start'>
                <div className='grid grid-cols-9 gap-0 border-4 border-black'>
                    {grid.map((row, rowIndex) =>
                        row.map((value, colIndex) => (
                            <div key={`${rowIndex}-${colIndex}`} className='relative w-10 h-10'>
                                <input
                                    ref={cellRefs.current[rowIndex][colIndex]}
                                    type='text'
                                    className={`w-full h-full text-center font-bold 
                                        ${colIndex % 3 === 2 && colIndex !== 8 ? 'border-r-4' : ''} 
                                        ${rowIndex % 3 === 2 && rowIndex !== 8 ? 'border-b-4' : ''} border border-gray-400 
                                        ${activeCell?.rowIndex === rowIndex && activeCell?.colIndex === colIndex ? 'bg-accent caret-transparent' : 'cursor-default'} 
                                        ${initialGrid[rowIndex][colIndex] !== 0 ? 'bg-gray-200 text-black' : ''} 
                                        ${cellStyles[`${rowIndex}-${colIndex}`] || ''}
                                        ${autoChecking && conflictingCells.includes(`${rowIndex}-${colIndex}`) ? 'text-error' : ''}
                                        ${activeCell && (activeCell.rowIndex === rowIndex || activeCell.colIndex === colIndex) && 
                                            !(activeCell.rowIndex === rowIndex && activeCell.colIndex === colIndex) && 
                                            initialGrid[rowIndex][colIndex] === 0 ? 'bg-secondary' : ''}
                                        ${activeCell && (activeCell.rowIndex === rowIndex || activeCell.colIndex === colIndex) && 
                                            !(activeCell.rowIndex === rowIndex && activeCell.colIndex === colIndex) && 
                                            initialGrid[rowIndex][colIndex] !== 0 ? 'bg-tertiary' : ''}
                                    `}
                                    value={userInputs[`${rowIndex}-${colIndex}`] !== undefined ? userInputs[`${rowIndex}-${colIndex}`] : (value === 0 ? '' : value)}
                                    onChange={(e) => handleInputChange(e.target.value, rowIndex, colIndex)}
                                    disabled={initialGrid[rowIndex][colIndex] !== 0}
                                    onFocus={() => handleFocus(rowIndex, colIndex)}
                                    style={{ caretColor: 'transparent' }}
                                    readOnly={true}
                                />
                                {candidateMode && value === 0 && (
                                    <div className="absolute top-0 left-0 w-full h-full flex flex-wrap justify-center items-center pointer-events-none">
                                        {arrangeCandidate(candidates[`${rowIndex}-${colIndex}`] || []).map((num, index) => (
                                            <span key={index} className="text-xxs text-black font-bold w-1/3 h-1/3 flex items-center justify-center">
                                                {num}
                                            </span>
                                        ))}
                                    </div>
                                )}
                                {!candidateMode && value === 0 && (
                                    <div className="absolute top-0 left-0 w-full h-full flex flex-wrap justify-center items-center pointer-events-none">
                                        {arrangeCandidate(manualCandidates[`${rowIndex}-${colIndex}`] || []).map((num, index) => (
                                            <span key={index} className="text-xxs text-black font-bold w-1/3 h-1/3 flex items-center justify-center">
                                                {num}
                                            </span>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))
                    )}
                </div>
            </div>
            <div className="flex justify-center w-full mt-4">
                <div className="btn-group w-52">
                    <button
                        className={`btn btn-sm w-1/2 flex-1 rounded-r-none ${manualCandidateMode === 'normal' ? 'btn-active' : ''}`}
                        onClick={() => setManualCandidateMode('normal')}
                    >
                        Normal
                    </button>
                    <button
                        className={`btn btn-sm w-1/2 flex-1 rounded-l-none ${manualCandidateMode === 'candidate' ? 'btn-active' : ''}`}
                        onClick={() => setManualCandidateMode('candidate')}
                    >
                        Candidate
                    </button>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center space-y-2 mt-4">
                <div className="flex justify-center space-x-2">
                    {[1, 2, 3, 4, 5].map(renderDigitButton)}
                </div>
                <div className="flex justify-center space-x-2">
                     {[6, 7, 8, 9].map(renderDigitButton)}
                    <button
                        className="btn btn-outline btn-lg w-16"
                        onClick={handleBackspace}   
                    >
                        <FontAwesomeIcon icon={faDeleteLeft} />
                    </button>
                </div>
                <div className="flex items-center justify-center mt-4">
                    <label className="flex items-center space-x-2 cursor-pointer">
                        <input
                            type="checkbox"
                            checked={candidateMode}
                            onChange={(e) => setCandidateMode(e.target.checked)}
                            className="checkbox"
                        />
                        <span className="label-text">Auto Candidate Mode</span>
                    </label>
                </div>
            </div>
            <CompletedModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} game="sudoku" completionTime={completionTime} gameWon={gameWon} />
            <InfoModal isOpen={isInfoModalOpen} onClose={() => setIsInfoModalOpen(false)} />
            {gameWon && <RatingAlert/>}
        </div>
    );
};

export default Sudoku;
