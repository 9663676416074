import React, { useState, useEffect } from 'react';
import { BarChart, Bar, YAxis, XAxis, Tooltip, LabelList, ResponsiveContainer, Pie, PieChart, Cell } from 'recharts';
import { getSecondsUntilMidnight, getNewYorkDate, formatDate } from './DateTime';
import { Preferences } from '@capacitor/preferences';

const calculateRiddleDistribution = async () => {
  try {
    const { value } = await Preferences.get({ key: 'riddleStats' });
    if (!value) return { 1: 0, 2: 0, 3: 0, 4: 0, NS: 0 };

    const stats = JSON.parse(value);
    const distribution = { 1: 0, 2: 0, 3: 0, 4: 0, NS: 0 };

    Object.values(stats).forEach(entry => {
      if (!entry.solved && entry.guesses === 4) {
        distribution.NS++;
      } else if (entry.solved) {
        distribution[entry.guesses]++;
      }
    });

    return distribution;
  } catch (error) {
    console.error('Error calculating riddle distribution:', error);
    return { 1: 0, 2: 0, 3: 0, 4: 0, NS: 0 };
  }
};

const StatRow = ({ title, value }) => (
  <div className="stat place-items-center">
    <div className="stat-title">{title}</div>
    <div className="stat-value">{value}</div>
  </div>
);

const DifficultySection = ({ difficulty, stats }) => (
  <div className="card bg-base-200 shadow-xl">
    <div className="card-body">
      <h3 className="card-title text-lg">{difficulty}</h3>
      <div className="stats stats-horizontal shadow w-full">
        <StatRow title="Games Played" value={stats.gamesPlayed} />
        <StatRow title="Win Rate" value={`${stats.winPercentage}%`} />
        <StatRow title="Average Time" value={stats.averageTime} />
      </div>
    </div>
  </div>
);

const renderSudokuStats = (sudokuStats) => (
  <div className="w-full space-y-4">
    {['Easy', 'Medium', 'Hard'].map(difficulty => (
      <DifficultySection 
        key={difficulty.toLowerCase()} 
        difficulty={difficulty} 
        stats={sudokuStats[difficulty.toLowerCase()]} 
      />
    ))}
  </div>
);

const CustomTooltip = ({ active, payload }) => {
  if (active && payload?.[0]) {
    return (
      <div className="bg-base-200 p-3 rounded-lg shadow-lg border border-base-300">
        <p className="font-medium">{`${payload[0].payload.name}`}</p>
        <p className="text-sm opacity-90">{`Count: ${payload[0].value}`}</p>
      </div>
    );
  }
  return null;
};

const CustomizedLabel = ({ x, y, width, height, value }) => {
  const yPos = y + (height / 2) + 5;
  const xPos = x + width - 10;
  return <text x={xPos} y={yPos} fill="white" textAnchor="end" dominantBaseline="middle">{value}</text>;
};

const StatBox = ({ label, value }) => (
  <div className="stat place-items-center">
    <div className="stat-value">{value}</div>
    <div className="stat-desc mt-2 text-center whitespace-pre-line">{label}</div>
  </div>
);

const TimeRemaining = ({ game }) => {
  const [timeRemaining, setTimeRemaining] = useState(getSecondsUntilMidnight());
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getSecondsUntilMidnight());
    }, 1000);
    return () => clearInterval(timer);
  }, []);
  const hours = Math.floor(timeRemaining / 3600);
  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;
  return (
    <div className="text-lg text-center font-bold">
      <p>Next {game}: {hours}:{minutes.toString().padStart(2, '0')}:{seconds.toString().padStart(2, '0')}</p>
    </div>
  );
};

const calculateAverageTime = async (game) => {
  try {
    let times = [];
    
    switch (game.toLowerCase()) {
      case 'crossword': {
        const { value } = await Preferences.get({ key: 'crosswordGameStates' });
        if (value) {
          const gameStates = JSON.parse(value);
          times = Object.values(gameStates)
            .filter(state => state.isCompleted)
            .map(state => state.completionTime)
            .filter(time => time > 0);
        }
        break;
      }
      case 'mini': {
        const { value } = await Preferences.get({ key: 'miniGameStates' });
        if (value) {
          const gameStates = JSON.parse(value);
          times = Object.values(gameStates)
            .filter(state => state.isCompleted)
            .map(state => state.completionTime)
            .filter(time => time > 0);
        }
        break;
      }
      case 'ridella': {
        const { value } = await Preferences.get({ key: 'riddleStats' });
        if (value) {
          const stats = JSON.parse(value);
          times = Object.values(stats)
            .filter(stat => stat.solved)
            .map(stat => stat.timeTaken)
            .filter(time => time > 0);
        }
        break;
      }
      default: {
        return "0:00";
      }
    }

    if (times.length === 0) return "0:00";

    const averageSeconds = Math.floor(times.reduce((sum, time) => sum + time, 0) / times.length);
    const minutes = Math.floor(averageSeconds / 60);
    const seconds = Math.floor(averageSeconds % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  } catch (error) {
    console.error('Error calculating average time:', error);
    return "0:00";
  }
};

const PlayerStats = ({ game, isLoading }) => {
  const GAME_CONFIGS = {
    crossword: {
      showAverageTime: false,
      distributionType: 'pie',
      nextGame: true,
      showBasicStats: true
    },
    mini: {
      showAverageTime: true,
      distributionType: 'pie',
      nextGame: true,
      showBasicStats: true
    },
    ridella: {
      showAverageTime: false,
      distributionType: 'bar',
      nextGame: true,
      showBasicStats: true
    },
    clique: {
      showAverageTime: false,
      distributionType: 'clique',
      nextGame: true,
      showBasicStats: true
    },
    proximity: {
      showAverageTime: false,
      distributionType: 'pie',
      nextGame: true,
      showBasicStats: true
    },
    sudoku: {
      showAverageTime: true,
      distributionType: 'sudoku',
      nextGame: true,
      showBasicStats: false
    }
  };
  const gameConfig = GAME_CONFIGS[game?.toLowerCase()] || {};

  const [stats, setStats] = useState({
    gamesPlayed: 0,
    winPercentage: 0,
    currentStreak: 0,
    maxStreak: 0,
    averageTime: "0:00"
  });

  const [distribution, setDistribution] = useState({ 1: 0, 2: 0, 3: 0, 4: 0, NS: 0 });
  useEffect(() => {
    const loadDistribution = async () => {
      if (game?.toLowerCase() === 'ridella') {
        const dist = await calculateRiddleDistribution();
        setDistribution(dist);
      }
    };
    
    loadDistribution();
  }, [game]);

  useEffect(() => {
    const calculateStats = async () => {
      try {
        const { value } = await Preferences.get({ key: 'playHistory' });
        if (!value) return;
        
        const playHistory = JSON.parse(value);
        const today = getNewYorkDate();
        
        const gamesPlayed = Object.values(playHistory).reduce((count, dayRecord) => 
          count + (dayRecord[game] ? 1 : 0), 0);

        const completedGames = Object.values(playHistory).reduce((count, dayRecord) => {
          const status = dayRecord[game];
          return count + (status === "completed" || status === "won" ? 1 : 0);
        }, 0);

        const winPercentage = gamesPlayed > 0 ? (completedGames / gamesPlayed) * 100 : 0;

        let currentStreak = 0;
        let maxStreak = 0;
        let tempStreak = 0;

        const sortedDates = Object.keys(playHistory).sort();
        for (const date of sortedDates) {
          const status = playHistory[date][game];
          if (status === "completed" || status === "won") {
            tempStreak++;
            maxStreak = Math.max(maxStreak, tempStreak);
          } else {
            tempStreak = 0;
          }

          if (date === today) {
            currentStreak = tempStreak;
          }
        }

        const averageTime = gameConfig.showAverageTime ? await calculateAverageTime(game) : "0:00";

        setStats(prevStats => ({
          ...prevStats,
          gamesPlayed,
          winPercentage,
          currentStreak,
          maxStreak,
          averageTime
        }));
      } catch (error) {
        console.error('Error calculating stats:', error);
      }
    };

    calculateStats();
  }, [game, gameConfig.showAverageTime]);

  const renderBasicStats = () => (
    <div className="stats shadow w-full">
      <StatBox label="Games Played" value={stats.gamesPlayed} />
      <StatBox label="Win Percentage" value={`${stats.winPercentage.toFixed(0)}%`} />
      <StatBox label="Current Streak" value={stats.currentStreak} />
      <StatBox label="Max Streak" value={stats.maxStreak} />
      {gameConfig.showAverageTime &&
        <StatBox label="Average Time" value={stats.averageTime} />
      }
    </div>
  );

  const [pieData, setPieData] = useState({
    won: 0,
    lost: 0,
    started: 0
  });

  useEffect(() => {
    const calculatePieData = async () => {
      if (!gameConfig.distributionType === 'pie') return;
      
      try {
        const { value } = await Preferences.get({ key: 'playHistory' });
        if (!value) return;

        const playHistory = JSON.parse(value);
        const data = {
          won: 0,
          lost: 0,
          started: 0
        };

        Object.values(playHistory).forEach(dayRecord => {
          const status = dayRecord[game];
          if (!status) return;

          switch (status.toLowerCase()) {
            case 'won':
            case 'completed':
              data.won++;
              break;
            case 'lost':
              data.lost++;
              break;
            case 'started':
            case 'inprogress':
              data.started++;
              break;
            default:
              break;
          }
        });

        setPieData(data);
      } catch (error) {
        console.error('Error calculating pie distribution:', error);
      }
    };

    calculatePieData();
  }, [game, gameConfig.distributionType]);

  const renderPieDistribution = () => {
    const data = [
      { name: 'Won', value: pieData.won, color: "#05B384" },
      { name: 'Lost', value: pieData.lost, color: "#fbbd23" },
      { name: 'Started', value: pieData.started, color: "#3abff8" }
    ];

    return (
      <div className="mt-8">
        <h3 className="font-bold text-lg text-center mb-4">Solve Distribution</h3>
        <div className="w-full h-48">
          <ResponsiveContainer>
            <PieChart>
              <Pie 
                data={data} 
                dataKey="value" 
                nameKey="name"
                outerRadius={60}
                label={({ name, percent }) => `${name}: ${(percent * 100).toFixed(0)}%`}
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  const renderBarDistribution = () => (
    <div className="mt-8">
      <h3 className="font-bold text-lg text-center mb-4">Solve Distribution</h3>
      <div className="w-full h-40">
        <ResponsiveContainer>
          <BarChart 
            data={Object.entries(distribution).map(([key, value]) => ({
              name: key,
              count: value
            }))} 
            layout="vertical" 
            barGap={5}
          >
            <YAxis type="category" dataKey="name" axisLine={false} tickLine={false} />
            <XAxis type="number" domain={[0, 'dataMax']} hide />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="count" fill="#8884d8" barSize={25} minPointSize={18}>
              <LabelList dataKey="count" content={<CustomizedLabel />} />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const [cliqueDistribution, setCliqueDistribution] = useState([
    { name: 'Mon', count: 0 },
    { name: 'Tue', count: 0 },
    { name: 'Wed', count: 0 },
    { name: 'Thu', count: 0 },
    { name: 'Fri', count: 0 },
    { name: 'Sat', count: 0 },
    { name: 'Sun', count: 0 }
  ]);

  useEffect(() => {
    const calculateCliqueDistribution = async () => {
      if (game?.toLowerCase() !== 'clique') return;
      
      try {
        const { value } = await Preferences.get({ key: 'playHistory' });
        if (!value) return;

        const playHistory = JSON.parse(value);
        const dayCount = [
          { name: 'Mon', count: 0 },
          { name: 'Tue', count: 0 },
          { name: 'Wed', count: 0 },
          { name: 'Thu', count: 0 },
          { name: 'Fri', count: 0 },
          { name: 'Sat', count: 0 },
          { name: 'Sun', count: 0 }
        ];
        
        const dayIndexMap = {
          'Monday': 0,
          'Tuesday': 1,
          'Wednesday': 2,
          'Thursday': 3,
          'Friday': 4,
          'Saturday': 5,
          'Sunday': 6
        };

        Object.entries(playHistory).forEach(([date, dayRecord]) => {
          if (dayRecord.clique === "completed" || dayRecord.clique === "won") {
            const { day } = formatDate(date);
            const dayIndex = dayIndexMap[day];
            if (dayIndex !== undefined) {
              dayCount[dayIndex].count++;
            }
          }
        });

        setCliqueDistribution(dayCount);
      } catch (error) {
        console.error('Error calculating clique distribution:', error);
      }
    };

    calculateCliqueDistribution();
  }, [game]);

  const renderCliqueDistribution = () => (
    <div className="mt-8">
      <h3 className="font-bold text-lg text-center mb-4">Wins by Day of Week</h3>
      <div className="w-full h-64">
        <ResponsiveContainer>
          <BarChart 
            data={cliqueDistribution}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis 
              dataKey="name" 
              axisLine={false}
              tickLine={false}
            />
            <YAxis 
              axisLine={false}
              tickLine={false}
              allowDecimals={false}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar 
              dataKey="count" 
              fill="#8884d8" 
              radius={[4, 4, 0, 0]}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );

  const [sudokuStats, setSudokuStats] = useState({
    easy: { gamesPlayed: 0, winPercentage: 0, averageTime: "0:00" },
    medium: { gamesPlayed: 0, winPercentage: 0, averageTime: "0:00" },
    hard: { gamesPlayed: 0, winPercentage: 0, averageTime: "0:00" }
  });

  useEffect(() => {
    const calculateSudokuStats = async () => {
      if (game?.toLowerCase() !== 'sudoku') return;
      
      try {
        const { value } = await Preferences.get({ key: 'sudoku' });
        if (!value) return;

        const sudokuHistory = JSON.parse(value);
        const difficulties = ['easy', 'medium', 'hard'];
        const newStats = {
          easy: { gamesPlayed: 0, winPercentage: 0, averageTime: "0:00" },
          medium: { gamesPlayed: 0, winPercentage: 0, averageTime: "0:00" },
          hard: { gamesPlayed: 0, winPercentage: 0, averageTime: "0:00" }
        };

        difficulties.forEach(difficulty => {
          const games = Object.values(sudokuHistory)
            .filter(day => day[difficulty])
            .map(day => day[difficulty]);

          const gamesPlayed = games.length;
          const completedGames = games.filter(game => game.completed).length;
          const winPercentage = gamesPlayed > 0 ? Math.round((completedGames / gamesPlayed) * 100) : 0;

          const completionTimes = games
            .filter(game => game.completed && game.completionTime)
            .map(game => game.completionTime);

          let averageTime = "0:00";
          if (completionTimes.length > 0) {
            const avgSeconds = Math.floor(completionTimes.reduce((sum, time) => sum + time, 0) / completionTimes.length);
            const minutes = Math.floor(avgSeconds / 60);
            const seconds = avgSeconds % 60;
            averageTime = `${minutes}:${seconds.toString().padStart(2, '0')}`;
          }

          newStats[difficulty] = {
            gamesPlayed,
            winPercentage,
            averageTime
          };
        });

        setSudokuStats(newStats);
      } catch (error) {
        console.error('Error calculating sudoku stats:', error);
      }
    };

    calculateSudokuStats();
  }, [game]);

  const renderNextGame = (game) => (
    <div className="mt-8">
      <TimeRemaining game={game} />
    </div>
  );

  if (isLoading) return <div className="loading loading-spinner loading-lg"></div>;

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold text-center mb-2">Personal Statistics</h2>


      {gameConfig.showBasicStats && renderBasicStats()}

      {gameConfig.distributionType === 'pie' && (
        <>
          <div className="w-full mb-4">
            {renderPieDistribution()}
          </div>
        </>
      )}

      {gameConfig.distributionType === 'bar' && (
        <>
          <div className="w-full mb-4">
            {renderBarDistribution()}
          </div>
        </>
      )}

      {gameConfig.distributionType === 'clique' && (
        <>
          <div className="w-full mb-4">
            {renderCliqueDistribution()}
          </div>
        </>
      )}

      {gameConfig.distributionType === 'sudoku' && (
        <>
          <div className="w-full mb-4">
            {renderSudokuStats(sudokuStats)}
          </div>
        </>
      )}

      {gameConfig.nextGame && renderNextGame(game)}
    </div>
  );
};

export default PlayerStats;
