// Streak.js
import React, { useEffect, useState } from "react";
import { Preferences } from '@capacitor/preferences';
import { getNewYorkDate, getDifferenceInDays, formatToDateObj, getPrevDate } from './DateTime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faStar, faBrain, faSeedling } from '@awesome.me/kit-636b1434d3/icons/duotone/solid';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { PlayOtherGameButton } from './CompletedModal'

const isDateComplete = (dateGames) => {
  return Object.values(dateGames).some(status => 
    ['completed', 'won'].includes(status)
  );
};

const processStreak = async (playHistory) => {
    const dates = Object.keys(playHistory)
      .filter(date => date !== 'undefined' && date !== 'null')
      .sort();
    
    if (dates.length === 0) {
      return {
        current: 0,
        max: 0,
        lastGameWonOn: null,
        wonOn: []
      };
    }
  
    let currentStreak = 0;
    let maxStreak = 0;
    let lastGameWonOn = null;
    let wonOn = [];

    const { value: existingStreakString } = await Preferences.get({ key: 'streak' });
    let existingStreak = {};
    if (existingStreakString) {
      existingStreak = JSON.parse(existingStreakString);
    }

    if (!existingStreak.wonOn) {
      wonOn = dates.filter(date => playHistory[date] && isDateComplete(playHistory[date])).sort();
    } else {
      wonOn = existingStreak.wonOn;
    }

    const today = getNewYorkDate();
    const yesterday = getPrevDate(today);
    let startDate = today;

    if (playHistory[yesterday] && isDateComplete(playHistory[yesterday]) && (!playHistory[today] || !isDateComplete(playHistory[today]))) {
        startDate = yesterday;
    }

    let currentDate = startDate;
    while (true) {
        if (wonOn.includes(currentDate)) {
            if (lastGameWonOn === null) {
                lastGameWonOn = currentDate;
            }
            currentStreak++;
            currentDate = getPrevDate(currentDate);
        } else {
            break;
        }
    }
  
    let tempStreak = 0;
    for (let i = 0; i < wonOn.length; i++) {
      if (i === 0) {
        tempStreak = 1;
      } else {
        const dayDiff = getDifferenceInDays(
          formatToDateObj(wonOn[i - 1]), 
          formatToDateObj(wonOn[i])
        );
        
        if (dayDiff === 1) {
          tempStreak++;
        } else {
          tempStreak = 1;
        }
      }
      maxStreak = Math.max(maxStreak, tempStreak);
    }
  
    maxStreak = Math.max(maxStreak, currentStreak);
    
    return {
      current: currentStreak,
      max: maxStreak,
      lastGameWonOn: lastGameWonOn || '',
      wonOn
    };
};

export const updateStreak = async () => {
    try {
      const { value: historyString } = await Preferences.get({ key: 'playHistory' });
      if (!historyString) {
        return null;
      }
      
      const playHistory = JSON.parse(historyString);
      const streakData = await processStreak(playHistory);
      
      const { value } = await Preferences.get({ key: 'streak' });
      let existingData = {};
      if (value) {
        existingData = JSON.parse(value);
      }
      
      const updatedStreakData = {
        ...streakData,
        shownStreak: existingData.shownStreak || null
      };
      
      await Preferences.set({
        key: 'streak',
        value: JSON.stringify(updatedStreakData)
      });
      
      return updatedStreakData;
    } catch (error) {
      console.error('Error updating streak:', error);
      return null;
    }
};

export const useStreakWonGameToday = () => {
  const [wonToday, setWonToday] = useState(false);

  useEffect(() => {
    const checkStreak = async () => {
      try {
        const { value } = await Preferences.get({ key: 'playHistory' });
        if (!value) {
          setWonToday(false);
          return;
        }
    
        const playHistory = JSON.parse(value);
        const todayString = getNewYorkDate();
        const todayGames = playHistory[todayString];
        
        if (!todayGames) {
          setWonToday(false);
          return;
        }

        const hasWon = Object.values(todayGames).some(status => 
          ['completed', 'won'].includes(status)
        );
        
        setWonToday(hasWon);
      } catch (error) {
        setWonToday(false);
      }
    };
    
    checkStreak();
  }, []);

  return wonToday;
};

export const useCurrentStreak = () => {
  const [currentStreak, setCurrentStreak] = useState(0);
  const wonToday = useStreakWonGameToday();

  useEffect(() => {
    let mounted = true;

    const getStreak = async () => {
      try {
        const { value } = await Preferences.get({ key: 'streak' });
        if (value && mounted) {
          const streakData = JSON.parse(value);
          setCurrentStreak(streakData.current);
        }
      } catch (error) {
        if (mounted) {
          setCurrentStreak(0);
        }
      }
    };

    getStreak();

    return () => {
      mounted = false;
    };
  }, [wonToday]);

  return currentStreak;
};

export const StreakModal = ({ isOpen, onClose }) => {  
    const [streakData, setStreakData] = useState({ current: 0, max: 0 });
    const [lastGamePlayed, setLastGamePlayed] = useState(null);
    const wonToday = useStreakWonGameToday();
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const { value: streakValue } = await Preferences.get({ key: 'streak' });
          if (streakValue) {
            setStreakData(JSON.parse(streakValue));
          }
  
          const { value: historyValue } = await Preferences.get({ key: 'playHistory' });
          if (historyValue) {
            const history = JSON.parse(historyValue);
            const dates = Object.keys(history)
              .filter(date => date !== 'undefined' && date !== 'null')
              .sort();
            
            if (dates.length > 0) {
              const lastDate = dates[dates.length - 1];
              const lastGames = history[lastDate];
              const lastGame = Object.keys(lastGames)[0];
              setLastGamePlayed(lastGame);
            }
          }
        } catch (error) {
          console.error('Error fetching streak data:', error);
        }
      };
  
      if (isOpen) {  
        fetchData();
      }
    }, [isOpen]); 
  
    const renderContent = () => {
        const hasStreak = streakData.current > 0;
        const isNewRecord = streakData.current === streakData.max && streakData.current > 0;
    
        if (hasStreak && wonToday) {
          return (
              <div className="p-6 bg-white rounded-b-lg dark:bg-black">
                <div className="flex items-center justify-center gap-2">
                  <FontAwesomeIcon icon={faStar} className="text-[#FF9500] text-xl" />
                  <p className="text-xl">
                    {isNewRecord 
                      ? "Congrats on setting a new streak record!" 
                      : "Congrats on reaching your latest milestone!"}
                  </p>
                </div>
              </div>
          );
        }
    
        if (hasStreak && !wonToday) {
          return (
              <div className="p-6 bg-white rounded-b-lg dark:bg-black">
                <div className="flex items-center justify-center gap-2">
                  <FontAwesomeIcon icon={faBrain} className="text-[#AC7B7D] text-xl" />
                  <p className="text-xl">
                    Win any game in the next 24 hours to extend your streak
                  </p>
                </div>
                {lastGamePlayed && (
                  <PlayOtherGameButton gameName={lastGamePlayed} />
                )}
              </div>
          );
        }
    
        return (
            <div className="p-6 bg-white rounded-b-lg dark:bg-black">
              <div className="flex items-center justify-center gap-2">
                <FontAwesomeIcon icon={faSeedling} className="text-xl text-[#2E8B57]" />
                <p className="text-xl">
                    Win any game today to get your streak started
                </p>
              </div>
            </div>
        );
      };
    
      return (
        <dialog className={`modal ${isOpen ? 'modal-open' : ''}`}>
          <div className="modal-box relative p-0 overflow-hidden">
            <form method="dialog">
                <label
                    htmlFor="my-modal-4"
                    className="btn btn-ghost btn-lg btn-circle text-white absolute right-2 top-2"
                    onClick={onClose}
                >
                    <FontAwesomeIcon icon={faTimes} className="text-2xl" />
                </label>
            </form>
            <div className="flex flex-col w-full">
              <div className="bg-[#FF9500] py-4 px-12 rounded-t-lg">
                <div className="flex justify-between items-start">
                  <div>
                    <p className="text-6xl font-bold text-white">{streakData.current}</p>
                    <p className="text-3xl font-bold text-white mt-1">day streak!</p>
                  </div>
                  <FontAwesomeIcon icon={faFire} className="text-8xl pr-8 text-white" />
                </div>
                <p className="text-xl font-bold text-white text-center mt-4">Max streak: {streakData.max} days</p>
              </div>
              {renderContent()}
            </div>
          </div>
          <form method="dialog" className="modal-backdrop">
            <button onClick={() => onClose()}>close</button>
          </form>
        </dialog>
      );
    
};


export const StreakAnimation = ({ oldStreak, newStreak }) => {
    const [showOldStreak, setShowOldStreak] = useState(true);
    
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowOldStreak(false);
      }, 100);
      return () => clearTimeout(timer);
    }, []);
  
    return (
      <div className="relative h-24 w-full flex justify-center items-center">
        <div
          className={`absolute transition-all duration-500 transform ${
            showOldStreak 
              ? "scale-100 translate-y-0 opacity-100" 
              : "scale-150 -translate-y-8 opacity-0"
          }`}
        >
          <span className="text-7xl font-bold text-[#FF9500]">{oldStreak}</span>
        </div>
        <div
          className={`absolute transition-all duration-500 transform ${
            showOldStreak 
              ? "scale-50 translate-y-8 opacity-0" 
              : "scale-100 translate-y-0 opacity-100"
          }`}
        >
          <span className="text-7xl font-bold text-[#FF9500]">{newStreak}</span>
        </div>
      </div>
    );
};
