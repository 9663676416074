// TermsOfService.js
import React from 'react';

const termsText = `
1. Acceptance of Terms
By accessing or using PuzPop, a daily word game app and website based in Baltimore, MD, you agree to be bound by these Terms of Service. If you do not agree with any part of these terms, you are prohibited from using or accessing this site.

2. Use License
- Permission is granted to temporarily download one copy of the materials (information or software) on PuzPop's website for personal, non-commercial transitory viewing only.
- This is the grant of a license, not a transfer of title, and under this license you may not:
  - modify or copy the materials;
  - use the materials for any commercial purpose, or for any public display (commercial or non-commercial);
  - attempt to decompile or reverse engineer any software contained on PuzPop's website;
  - remove any copyright or other proprietary notations from the materials;
  - transfer the materials to another person or "mirror" the materials on any other server.
- This license shall automatically terminate if you violate any of these restrictions and may be terminated by PuzPop at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format.

3. Disclaimer
- The materials on PuzPop's website are provided on an 'as is' basis. PuzPop makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.
- Further, PuzPop does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.

4. Limitations
In no event shall PuzPop or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on PuzPop's website, even if PuzPop or a PuzPop authorized representative has been notified orally or in writing of the possibility of such damage.

5. Accuracy of Materials
The materials appearing on PuzPop's website could include technical, typographical, or photographic errors. PuzPop does not warrant that any of the materials on its website are accurate, complete, or current. PuzPop may make changes to the materials contained on its website at any time without notice. However, PuzPop does not make any commitment to update the materials.

6. Links
PuzPop has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by PuzPop of the site. Use of any such linked website is at the user's own risk.

7. Modifications to Terms of Service
PuzPop may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these Terms of Service.

8. Governing Law
These terms and conditions are governed by and construed in accordance with the laws of Maryland and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location.

9. Contact Information
For any questions or concerns about these terms, please contact us via email at dylan@puzpop.com.
`;

const TermsOfService = () => {
    return (
        <>
            <div >
                <h2>Terms of Service</h2>
                <div>{termsText.split('\n').map((paragraph, idx) => <p key={idx}>{paragraph}</p>)}</div>
            </div>
        </>
    );
};

export default TermsOfService;
