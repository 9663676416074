// playHistoryUtil.js
import { Preferences } from '@capacitor/preferences';
import { updateStreak } from './Streak';
import { getNewYorkDate } from './DateTime';

export const getPlayHistory = async () => {
  const { value } = await Preferences.get({ key: "playHistory" });
  return value ? JSON.parse(value) : {};
};

export const updatePlayHistory = async (date, game, status) => {
  const playHistory = await getPlayHistory();
  if (!playHistory[date]) {
    playHistory[date] = {};
  }
  playHistory[date][game] = status;
  await Preferences.set({
    key: "playHistory",
    value: JSON.stringify(playHistory)
  });

  const today = getNewYorkDate();
  if (date === today && (status === "won" || status === "completed")) {
    const { value: streakString } = await Preferences.get({ key: 'streak' });
    if (streakString) {
      const streak = JSON.parse(streakString);
      if (streak.wonOn && !streak.wonOn.includes(today)) {
        streak.wonOn.push(today);
        await Preferences.set({
          key: 'streak',
          value: JSON.stringify(streak)
        });
      }
    }
  }
  updateStreak();
};

export const getGameStatus = async (date, game) => {
  const playHistory = await getPlayHistory();
  return playHistory[date] && playHistory[date][game] ? playHistory[date][game] : null;
};