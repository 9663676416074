// useBackHandler.js
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { getNewYorkDate } from '../components/DateTime';

export const useBackHandler = (returnToDate) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      const handleBackButton = () => {
        const currentPath = window.location.pathname;
        
        if (currentPath !== '/') {
          navigate('/', { 
            state: { 
              date: returnToDate || location.state?.date || getNewYorkDate()
            }
          });
        } else {
          CapacitorApp.exitApp();
        }
      };

      const backHandler = CapacitorApp.addListener('backButton', handleBackButton);
      return () => backHandler.remove();
    }
  }, [navigate, location, returnToDate]);
};