import React from 'react';
import { Link } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Footer() {
  return (
    // <div className="btm-nav flex justify-around" style={{ height: '4rem', boxShadow: '0 -4px 10px rgba(0, 0, 0, 0.2)', maxWidth: '600px', margin: '0 auto' }}>
    //   <button className="flex flex-col items-center">
    //     <Link to="/" className="flex flex-col items-center">
    //       <i className="fa-solid fa-house text-xl pt-1"></i>
    //       <span className="btm-nav-label text-sm mt-1 pb-1">Home</span>
    //     </Link>
    //   </button>
    //   <button className="flex flex-col items-center">
    //     <Link to="/" className="flex flex-col items-center">
    //       <i className="fa-solid fa-list text-xl pt-1"></i>
    //       <span className="btm-nav-label text-sm mt-1 pb-1">Sections</span>
    //     </Link>
    //   </button>
    //   <button className="flex flex-col items-center text-primary">
    //     <Link to="/" className="flex flex-col items-center text-primary">
    //       <i className="fa-solid fa-puzzle-piece text-2xl pt-1"></i>
    //       <span className="btm-nav-label text-sm mt-1 pb-1">Games</span>
    //     </Link>
    //   </button>
    //   <button className="flex flex-col items-center">
    //     <Link to="/" className="flex flex-col items-center">
    //       <i className="fa-solid fa-user text-xl pt-1"></i>
    //       <span className="btm-nav-label text-sm mt-1 pb-1">Account</span>
    //     </Link>
    //   </button>
    // </div>
    <div className="flex justify-center gap-4 p-4 text-sm">
      <Link to="/privacy" className="hover:text-blue-800">
        Privacy Policy
      </Link>
      <span>|</span>
      <Link to="/tos" className="hover:text-blue-800">
        Terms of Service
      </Link>
    </div>
  );
}

export default Footer;
